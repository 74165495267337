// =================================================================================================
// Main App
// =================================================================================================

import '../App.scss';
import { useContext, useEffect, useState } from 'react';
import { getApiProducts } from '../apiCalls';
import { DeploymentHost, TBranding, TError, TJourneyConfig, TJourneyMode, TProductData } from '../types';
import { Journey } from '../components/Journey';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SuccessPage } from '../components/SuccessPage';
import {
  configureAPIs,
  DEFAULT_UUID,
  getBranding,
  getProduct1,
  // getProduct,
  // getProducts,
  getQuote,
  getQuoteSummary,
} from '../apiCalls';

import { JourneyPicker } from '../components/JourneyPicker';
import { defaultBrand, defaultTheme, V2RenderVersionThemeExtras } from '../defaultBranding';
import { CenteredError } from '../components/CenteredError';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { JourneyEditApp } from '../JourneyEditApp';
import { getEnvParams } from '../utils';
import { ProductsContext } from '../ProductsApiContext';
import { allJourneyConfigs, getJourneyById } from '../journeyConfigs';
import { activeJourneyContext } from '../activeJourneyContext';
import ScratchAndDentPersonalOneEdge from '../journeyConfigs/pro-e81ce45f-6909-485f-81d7-f7935065446d/ScratchAndDentPersonalOneEdge.json';
import cedar from '../journeyConfigs/pro-dc90cf75-a248-4d57-930f-b7373b6d39db/cedar.json';
import productTester from '../journeyConfigs/no-id/Product Tester.json';
import { GroupsFieldProvider } from '../CollapsableSectionsContext';
import SuccessPageV2 from '../components/SuccessPageV2';
import { hexToRGBA } from '../utils';
import AppliedContextProvider from '../AppliedContext';
import { useCookies } from 'react-cookie';
import { CookieBanner } from '../components/CookieBanner';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';

console.log('Loaded on ' + new Date());

// =================================================================================================
// Main component
// =================================================================================================

const envParams = getEnvParams();

const Home = ({ fetchedBranding }: { fetchedBranding: any }) => {
  console.log('fetchedBranding from cookies', fetchedBranding);
  // -----------------------------------------------------------------------------------------------
  // App state
  // -----------------------------------------------------------------------------------------------

  const [journeys, setJourneys] = useState<TProductData[] | null>(null);
  const [activeJourney, setActiveJourney] = useState<TJourneyConfig | null>(null);
  const [theme, setTheme] = useState(defaultTheme);
  const [branding, setBranding] = useState<TBranding>(defaultBrand);
  const [journeyMode, setJourneyMode] = useState<TJourneyMode>('LOADING');
  const [error, setError] = useState<TError>({});
  const [productData, setProductData] = useState<TProductData | null>(null);
  const [successToken, setSuccessToken] = useState('');
  const [productsLoading, setProductsLoading] = useState(true);

  const [cookies] = useCookies(['cookieBanner']);
  console.log(cookies);

  const { updateProductData } = useContext(ProductsContext);
  const url = process.env.REACT_APP_TEMP_ENDPOINT;
  console.log(url);

  const { userSessionToken, quoteId, productId } = envParams;
  const uuid = fetchedBranding ? fetchedBranding.uuid : envParams.uuid;
  console.log('uuid', uuid);

  // -----------------------------------------------------------------------------------------------
  // useEffect for loading initial data from APIs
  // -----------------------------------------------------------------------------------------------

  console.log('environment is', JSON.stringify(envParams, null, 2));

  useEffect(() => {
    const fetchFromAPIs = async () => {
      const productsFromDynamoDb = await getApiProducts(uuid, fetchedBranding?.slug);
      if (productsFromDynamoDb) {
        setProductsLoading(false);
      }

      configureAPIs(envParams.hostname as DeploymentHost, envParams.uuid ?? undefined);
      // -------------------------------------------------------------------------------------------
      // Early bail
      // -------------------------------------------------------------------------------------------
      if (!uuid && !userSessionToken && !quoteId && envParams.mode !== 'EDIT') {
        setJourneyMode('ERROR');
        setError({
          title: 'No Parameters',
          content:
            `None of the required parameters are specified. Remember to add e.g. ` +
            `?uuid=${DEFAULT_UUID} to the URL.`,
        });

        return;
      }

      // -------------------------------------------------------------------------------------------
      // If userSessionToken exists, we got redirected to from Go Cardless
      // Success page does its own API calls for branding, etc.
      // -------------------------------------------------------------------------------------------
      if (userSessionToken !== null) {
        setJourneyMode('SUCCESS');
        return;
      }

      // -------------------------------------------------------------------------------------------
      // If quoteId exists, we are resuming a quote or doing an MTA
      // -------------------------------------------------------------------------------------------
      if (quoteId !== null) {
        // Get the quote summary (it matches the original new quote POST response)
        const summary = await getQuoteSummary(quoteId);
        if (summary === null) {
          setJourneyMode('ERROR');
          setError({
            title: 'Problem Fetching Data',
            content: 'The quote summary could not be fetched.',
          });
          return;
        }

        // Get the full quote (it has our captured formValues)
        const quote = await getQuote(quoteId);
        if (quote === null) {
          setJourneyMode('ERROR');
          setError({ title: 'Problem Fetching Data', content: 'The quote could not be fetched.' });
          return;
        }
        configureAPIs(envParams.hostname as DeploymentHost, quote.mainBrokerId);

        const product = await getProduct1(quote.productId, productsFromDynamoDb.data);
        const journey: TJourneyConfig = product?.journeyConfig;
        if (journey === null) {
          setJourneyMode('ERROR');
          setError({
            title: 'Problem Fetching Data',
            content: 'The journey config could not be fetched.',
          });
          return;
        }
        // const branding = await getBranding(quote.mainBrokerId);
        const branding = fetchedBranding ?? (await getBranding(quote.mainBrokerId));
        console.log('homeBranding', branding);

        if (branding === null) {
          setJourneyMode('ERROR');
          setError({
            title: 'Problem Fetching Data',
            content: 'The branding could not be fetched.',
          });
          return;
        }

        if (!handleBranding(branding, journey?.renderVersion)) {
          setJourneyMode('ERROR');
          setError({
            title: 'Unexpected branding response',
            content: `The format of the branding response is invalid or incomplete.`,
          });
          return;
        }

        // update products context
        // const product = await getProduct1(quote.productId, datProducts.data);
        console.log('homeProduct', product);
        if (!product || product.state !== 'active' || !product.published) {
          setJourneyMode('ERROR');
          setError({
            title: 'Product Unavailable.',
            content: 'Sorry. This product is not available.',
          });
          return;
        }
        setProductData(product);
        updateProductData(product);

        // Populate initial values with submitted data from quote and quote summary
        const initialFormValues = quote.quoteRequest.originalData.formValues;
        const initialValues = {
          ...initialFormValues,
          paymentIntent: quote?.paymentDetails,
          quote: { ...summary, useNewMtaQp: quote?.useNewMtaQp },
        };
        setActiveJourney({ ...journey, initialValues });

        if (quote.state === 'QUOTE') {
          setJourneyMode('RESUME');
          console.log('Resuming a created quote');
        } else if (quote.state === 'BOUND' || quote.state === 'REFERRED') {
          setJourneyMode('MTA');
          console.log('Perform MTA on bound quote');
        } else {
          setJourneyMode('ERROR');
          setError({
            title: 'Invalid Quote State',
            content: 'The quote is not in a state that can be worked with.',
          });
        }
        return;
      }

      // -------------------------------------------------------------------------------------------
      // If uuid exists, we want to fetch a specific branding
      // -------------------------------------------------------------------------------------------
      if (uuid != null) {
        // const branding = await getBranding(uuid);
        const branding = fetchedBranding ?? (await getBranding(uuid));
        console.log('homeBranding', branding);

        if (branding === null) {
          setJourneyMode('ERROR');
          setError({
            title: 'Problem Fetching Data',
            content: 'The branding could not be fetched.',
          });
          return;
        }
        console.log(`Checking Branding`);
        if (!handleBranding(branding, activeJourney?.renderVersion)) {
          setJourneyMode('ERROR');
          setError({
            title: 'Unexpected branding response',
            content: `The format of the branding response is invalid or incomplete.`,
          });
          return;
        }
      } else {
        setJourneyMode('ERROR');
        setError({
          title: 'Missing UUID',
          content: 'No broker UUID was specified.',
        });
        return;
      }

      // -------------------------------------------------------------------------------------------
      // If productId exists, try to fetch the journey for it. Otherwise, fetch all journeys
      // -------------------------------------------------------------------------------------------
      if (productId != null) {
        console.log(productId);
        const product = await getProduct1(productId, productsFromDynamoDb.data);
        console.log(product);
        if (!product || product.state !== 'active' || !product.published) {
          setJourneyMode('ERROR');
          setError({
            title: 'Product Unavailable.',
            content: 'Sorry. This product is not available.',
          });
          return;
        }
        setProductData(product);
        updateProductData(product);
        // const journey = getJourneyById(productId);
        if (product?.journeyConfig !== null) {
          handleBranding(fetchedBranding ?? branding, product.journeyConfig?.renderVersion);
          setActiveJourney(product?.journeyConfig);

          setJourneyMode('RESUME'); // Prevent going back to shopfront
        }
      } else {
        // const journeys = await getProducts(uuid);
        const journeys = productsFromDynamoDb?.data;
        console.log('homeJourneys', journeys);
        setJourneys(journeys);
        setJourneyMode('DEFAULT');
      }
    };

    switch (envParams.mode) {
      case 'EDIT':
        configureAPIs(envParams.hostname as DeploymentHost);
        setJourneyMode('EDIT');
        setProductsLoading(false);
        break;
      case 'TEST':
        configureAPIs(envParams.hostname as DeploymentHost);
        setJourneys(allJourneyConfigs as unknown as TProductData[]);
        setJourneyMode('TEST');
        setActiveJourney(productTester as TJourneyConfig);
        setProductsLoading(false);
        break;
      default:
        fetchFromAPIs();
    }
  }, []);

  useEffect(() => {
    document.title = activeJourney?.product?.title || 'Welcome';
  }, [activeJourney]);

  // -----------------------------------------------------------------------------------------------
  // Theme and branding
  // -----------------------------------------------------------------------------------------------

  const handleBranding = (branding: TBranding, renderVersion: 'V1' | 'V2' = 'V1') => {
    console.log({ renderVersion });
    console.log('Creating Theme: ', branding);
    try {
      const brandedTheme = createTheme(
        renderVersion === 'V2'
          ? {
              ...V2RenderVersionThemeExtras,
              palette: {
                primary: {
                  main: branding.main_color ?? '#3B90CF',
                },
                secondary: {
                  main: hexToRGBA(defaultBrand.main_color, 0.08),
                },
              },
            }
          : {
              palette: {
                primary: {
                  main: branding.main_color ?? '#3B90CF',
                },
              },
            },
      );
      if (branding.name) {
        document.title = branding.name;
      }
      setBranding(branding);
      setTheme(brandedTheme);
    } catch (e) {
      return false;
    }
    return true;
  };

  // -----------------------------------------------------------------------------------------------
  // Main rendering
  // -----------------------------------------------------------------------------------------------

  if (journeyMode === 'EDIT') {
    return <JourneyEditApp />;
  }

  if (journeyMode === 'LOADING' && !fetchedBranding) {
    return <LoadingIndicator />;
  }

  if (journeyMode === 'ERROR') {
    return <CenteredError title={error.title} content={error.content} />;
  }

  if (journeyMode === 'SUCCESS') {
    if (activeJourney?.renderVersion === 'V2') {
      return (
        <SuccessPageV2
          config={activeJourney}
          quoteId={envParams.userSessionToken}
          branding={fetchedBranding ?? branding}
        />
      );
    } else {
      return <SuccessPage quoteId={envParams.userSessionToken} branding={fetchedBranding ?? branding} />;
    }
  }
  if (successToken) {
    if (activeJourney?.renderVersion === 'V2') {
      return <SuccessPageV2 config={activeJourney} quoteId={successToken} branding={fetchedBranding ?? branding} />;
    } else {
      return <SuccessPage quoteId={successToken} branding={fetchedBranding ?? branding} />;
    }
  }

  const groupFields = activeJourney?.sections?.flatMap((s) => s.fields.filter((f) => f.type === 'group'));

  return (
    <activeJourneyContext.Provider value={activeJourney}>
      <ThemeProvider theme={theme}>
        {activeJourney === null && !productId && !quoteId ? (
          <Routes>
            <Route
              path="*"
              element={
                <JourneyPicker
                  productsLoading={productsLoading}
                  branding={fetchedBranding ?? branding}
                  journeys={journeys || []}
                  setActiveJourney={setActiveJourney}
                  mode={journeyMode}
                  theme={theme}
                  setTheme={setTheme}
                />
              }
            />
          </Routes>
        ) : (
          <AppliedContextProvider>
            <GroupsFieldProvider groupIds={groupFields?.map((f) => f.name) ?? []}>
              <Journey
                productsLoading={productsLoading}
                branding={fetchedBranding ?? branding}
                setJourneyMode={setJourneyMode}
                config={{
                  ...activeJourney,
                  product: {
                    ...activeJourney?.product,
                    title: productData?.productName ?? activeJourney?.product.title,
                  },
                }}
                setSuccessToken={setSuccessToken}
                setActiveJourney={setActiveJourney}
                mode={journeyMode}
              />
            </GroupsFieldProvider>
          </AppliedContextProvider>
        )}
        {!cookies.cookieBanner && <CookieBanner uuid={String(uuid)} />}
      </ThemeProvider>
    </activeJourneyContext.Provider>
  );
};

export default Home;
