import React from 'react';
import { TFieldChangeHandler, TFormData, TFormField, TJourneyConfig } from '../../types';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export default function DynamicSelect(props: {
  field: TFormField;
  value: any;
  config: TJourneyConfig;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
  busy: boolean;
}) {
  const { field, value, formData, changeHandler, busy } = props;

  const dependentValue = field?.params?.conditionalOptionsDependent
    ? formData?.values[field?.params?.conditionalOptionsDependent]
    : null;

  const options =
    field.conditionalOptions && dependentValue
      ? field.conditionalOptions?.find((o) => o.key === dependentValue)?.options || []
      : field.options || [];

  const localChangeHandler = (event: any, newValue: any) => {
    changeHandler(field, newValue?.value || event.target.value);
  };

  const isLargeList = field?.variant === 'autocomplete';

  return (
    <FormControl fullWidth variant="outlined" margin="normal" disabled={busy || options.length === 0}>
      <InputLabel>{field?.title}</InputLabel>
      {isLargeList ? (
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.value === value) || null}
          onChange={localChangeHandler}
          disabled={field.disabled}
          renderInput={(params) => (
            <TextField {...params} label={field?.title} variant="outlined" fullWidth disabled={busy} />
          )}
        />
      ) : (
        <Select
          disabled={field.disabled}
          labelId={field.label + '-label'}
          label={field.title}
          value={value ?? ''}
          onChange={localChangeHandler}
          MenuProps={{
            style: {
              maxHeight: 400,
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}
