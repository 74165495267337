// =================================================================================================
// This is the dynamic form that gets rendered from a config
// =================================================================================================
import { Route, Routes, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
// import { buildPolicyPayload, updateHistoricIds } from "../ERS/payload";
import { JourneyContext } from '../JourneyContext';
import { AppliedTransactions, createQuote, patchQuote, updateQuoteStage } from '../apiCalls';
import { validateField } from '../fieldValidation';
import {
  checkDependents,
  checkOptionDependents,
  checkRules,
  conditionMet,
  getFormData,
  initDependencies,
  replacePlaceholders,
} from '../formHandling';
import { dispatchTrackingEvent } from '../modules/googleAnalytics';
import {
  IStringIndex,
  TAlert,
  TBranding,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
  TJourneyMode,
  TJourneySection,
  TQuote,
} from '../types';
import { Branding } from './Branding';
import Confetti from './Confetti';
import { DebugView } from './DebugView';
import { JourneyHeader } from './JourneyHeader';
import { JourneySection } from './JourneySection';
import { MTASuccessPage } from './MTASuccessPage';
import { RegulatoryFooter } from './RegulatoryFooter';
import { SubmitButton } from './SubmitButton';
import { applyRule } from '../utils/rulesEngine';
import { PaymentContext } from '../PaymentContext';
import { findMatchingField, getEnvParams } from '../utils';
import QuoteListingSection from './QuoteListing/QuoteListingPage';
import QuoteListingLoader from './QuoteListing/QuoteListingLoader';
import DynamicFooter from './DynamicFooter';
import { AppliedContext } from '../AppliedContext';
import { LoadingIndicator } from './LoadingIndicator';
import payloads from '../utils/payloads/payloads';
import { useGroupsField } from '../CollapsableSectionsContext';
import CustomAlert from './FormFields/CustomAlert';
import QuoteListingError from './QuoteListing/QuoteListingError';

// =================================================================================================
// Main component
// =================================================================================================

export const Journey = (props: {
  mode: TJourneyMode;
  config: any;
  branding?: TBranding;
  productsLoading: boolean;
  setJourneyMode?:any;
  setSuccessToken: React.Dispatch<React.SetStateAction<string>>;
  setActiveJourney?: (c: TJourneyConfig | null) => void;
}) => {
  // -----------------------------------------------------------------------------------------------
  // State and variables
  // -----------------------------------------------------------------------------------------------

  const { selectedAppliedQuote, updateSelectedQuote } = useContext(AppliedContext);
  const { markCompleted, groupIds } = useGroupsField();
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [busy, setBusy] = useState(false);
  const [fetchingQuotes, setFetchingQuotes] = useState(false);
  const [formData, setFormData] = useState<TFormData>({
    values: {},
    validations: {},
  });

  const [showMTASuccess, setShowMTASuccess] = useState(false);
  const [NFResponse, setNFResponse] = useState<any>(undefined);
  const [journeyLocked, setJourneyLocked] = useState(false);

  const [submitAlert, setSubmitAlert] = useState<TAlert | undefined>();
  const [quoteListingError, setQuoteListingError] = useState<boolean | null>(null);
  const [appliedMTAError, setAppliedMTAError] = useState(false);

  const { config, mode, branding, productsLoading, setJourneyMode } = props;
  const sections = config.sections;
  const section: TJourneySection | undefined = activeIndex >= 0 ? sections[activeIndex] : undefined;
  const finalSection = sections?.find((section: any) => section.isFinal);

  const { paymentConfirmed, paymentDetails } = useContext(PaymentContext);
  const paymentComponentRef = useRef<any>();

  const { isEmbedded, prefill, sourceIdentifier } = getEnvParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const urlParams = new URLSearchParams(location.search);

  // -----------------------------------------------------------------------------------------------
  // useEffects
  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 20);
  }, [section]);

  useEffect(() => {
    console.log('Journey config has changed');
    if (!config?.sections) {
      return;
    }

    if (prefill) {
      config.initialValues = { ...payloads[config?.product?.id], ...config?.initialValues };
    }

    preprocessInitialValues(config.initialValues ?? {});

    let allFields: TFormField[] = [];
    for (let s of config.sections) {
      allFields = [...allFields, ...s.fields];
    }

    const getInitialValidations = (initialValues: IStringIndex<any>) => {
      const validations: IStringIndex<boolean> = {};
      for (let [fieldName, fieldValue] of Object.entries(initialValues)) {
        const matchingField = findMatchingField(allFields, fieldName);
        if (matchingField) {
          if (matchingField.autoClear && !prefill) {
            validations[fieldName] = false;
          } else {
            validations[fieldName] = validateField(matchingField, fieldValue, initialValues);
          }
        }
      }
      return validations;
    };

    const initialValidations = config.initialValues ? getInitialValidations(config.initialValues) : {};
    const newIndex = 0;
    navigate(
      `/journey/${sections[newIndex].name?.replace(/\s+/g, '-')?.toLowerCase()}-${newIndex}?${urlParams?.toString()}`,
    );
    setActiveIndex(newIndex);

    initDependencies(allFields, mode);
    const newFormData = getFormData(
      allFields,
      {
        values: config.initialValues || {},
        validations: initialValidations,
      },
      mode,
      config,
    );

    if (newFormData?.values?.quote?.journeyLocked) {
      setJourneyLocked(true);
    }

    if (newFormData?.values?.quote?.appliedQuoteDetails) {
      updateSelectedQuote?.(newFormData?.values?.quote?.appliedQuoteDetails);
    }

    allFields.forEach((field) => checkRules(field, allFields, newFormData.values, newFormData.validations, config));

    setFormData(newFormData);
    setSubmitAlert(undefined);
  }, [config, sections, mode]);

  useEffect(() => {

    // Handle activeIndex updates
    const urlParamIndex = parseInt(location.pathname.split('/').pop()?.split('-')?.at(-1) ?? '');
    const newActiveIndex = isNaN(urlParamIndex) ? activeIndex : urlParamIndex;
    if (activeIndex !== newActiveIndex) {
      setActiveIndex(newActiveIndex);
    }

    // Handle redirection
    const productId = config?.product?.id;
    const origin = window.location.origin;
    if (mode === 'SUCCESS' || NFResponse !== undefined || showMTASuccess) {
      if (urlParams.has('productId')) {
        setJourneyMode("LOADING")
        window.location.href = `${origin}/?productId=${productId}`;
        window.location.reload()
      } else {
        setJourneyMode("LOADING")
        window.location.href = `${origin}/`;
        window.location.reload()
      }
    }else if (activeIndex === 0 && !productsLoading && !location.pathname.includes('journey')) {
      if (urlParams.has('productId')) {
        setJourneyMode("LOADING")
        window.location.href = `${origin}/?productId=${productId}`;
        window.location.reload()
      } else {
        setJourneyMode("LOADING")
        window.location.href = `${origin}/`;
        window.location.reload()
      }
    }
  }, [location]);

  // -----------------------------------------------------------------------------------------------
  // Field change handler for updating values and validations in state
  // -----------------------------------------------------------------------------------------------

  const changeHandler: TFieldChangeHandler = async (field: TFormField, newValue: any) => {
    let allFields: TFormField[] = [];
    for (let s of config.sections) {
      allFields = [...allFields, ...s.fields];
    }

    //console.log("Current values:", JSON.stringify(formData.values, null, 2));
    // console.log(`Field "${field.name}" has new value ${JSON.stringify(newValue)}`);
    let newValidations = { ...formData.validations };
    let newValues = { ...formData.values, [field.name]: newValue };

    const validation = validateField(field, newValue, newValues);
    newValidations[field.name] = validation;
    checkDependents(field, allFields, newValues, newValidations, config, mode);
    checkOptionDependents(field, allFields, newValues, newValidations);
    // NEW: uses json-rules-engine structure
    await checkRules(field, allFields, newValues, newValidations, config);
    const newFormData: TFormData = {
      values: { ...formData.values, ...newValues },
      validations: { ...formData.validations, ...newValidations },
    };
    // console.log("New values:", JSON.stringify(newFormData.values, null, 2));
    setFormData(newFormData);
  };

  // -----------------------------------------------------------------------------------------------
  // Main submit handler
  // -----------------------------------------------------------------------------------------------

  const handleSubmit = async (e?: any, formValues?: TFormData['values']) => {
    if (groupIds && groupIds.length > 0) {
      groupIds?.map((_, index) => markCompleted?.(index));
    }
    setQuoteListingError(false);
    // Clear submit error if one exists.
    if (submitAlert) {
      setSubmitAlert(undefined);
    }

    // Clean up form data. Right now this just means removing string whitespace.
    const cleanValues: IStringIndex<any> = {};
    for (let [fieldName, fieldValue] of Object.entries({ ...formData.values, ...formValues })) {
      if (typeof fieldValue === 'string') {
        cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
      } else {
        cleanValues[fieldName] = fieldValue;
      }
    }

    if (formValues) {
      setFormData({
        ...formData,
        values: { ...formData.values, ...formValues },
      });
    }

    // Track user input via GTM
    try {
      dispatchTrackingEvent(config, formData, activeIndex, props.branding);
    } catch (error: any) {
      console.error(`Google Dispatcher failed: ${error?.message}`);
    }

    // bad but will do for now
    if (
      section?.isPayment &&
      cleanValues?.MTA &&
      cleanValues?.MTA.stripeCollects &&
      !cleanValues.MTA.stripeMTAConfirmed &&
      !paymentConfirmed &&
      Number(cleanValues?.MTA.mtaAdjustment) + Number(cleanValues?.MTA.mtaFee) > 0
    ) {
      const results = await paymentComponentRef.current.triggerPayment(cleanValues);
      if (results && results?.error) {
        setSubmitAlert({
          body: results.error.message,
          severity: 'error',
        });
        return;
      } else {
        cleanValues['paymentIntent'] = results?.paymentIntent;
      }
    } else if (section?.isPayment && !cleanValues?.MTA) {
      const results = await paymentComponentRef.current.triggerPayment(cleanValues);
      if (results && results?.error) {
        setSubmitAlert({
          body: results.error.message,
          severity: 'error',
        });
        return;
      } else {
        cleanValues['paymentIntent'] = results?.paymentIntent;
      }
    } else if (paymentConfirmed && paymentDetails) {
      cleanValues['paymentIntent'] = paymentDetails;
    }

    // What we do with the form data depends on the quote's existence and state.
    const quote = formData.values?.quote;
    // NEW: if we're in step 1, or we don't have a quote we call the new quotes api
    const step1 = activeIndex === 0;
    // const isQuoteListing = sections[activeIndex]?.isQuoteListing ?? sections[activeIndex + 1]?.isQuoteListing;
    if (!quote && step1) {
      // Quote does not exist yet, we might create one.
      // if step 1 and there is already a quote then skip to step 2.
      // if (quote && quote.quoteId) prepNextSection(cleanValues, nextSection);
      cleanValues["sourceIdentifier"] = sourceIdentifier
      submitNewQuote(cleanValues);
    } else {
      try {
        switch (quote.state) {
          case 'QUOTE':
            // Update an existing quote with either PATCH or BOUND.
            submitQuoteUpdate(cleanValues);
            break;
          case 'BOUND':
          case 'REFERRED':
            if (quote.state === 'REFERRED' && quote.type === 'QTO') {
              submitQuoteUpdate(cleanValues);
            } else {
              // This will always be an MTA.
              submitQuoteMTA(cleanValues);
            }
            break;
        }
      } catch (error: any) {
        console.error(error);
        setSubmitAlert({
          body: `An error occurred while submitting quote`,
          severity: 'error',
        });
      }
    }
  };

  // If next section exists, prepare form data and proceed to it.
  const prepNextSection = (values: IStringIndex<any>, nextSection: TJourneySection | undefined) => {
    let newActiveIndex = handleDynamicSections(
      sections,
      activeIndex,
      formData,
      'forward',
      props.mode,
      isPaymentSucceeded,
      disablePrePayment,
    );
    if (nextSection) {
      const nextData: TFormData = getFormData(
        nextSection.fields,
        {
          ...formData,
          values: values,
        },
        mode,
        config,
      );
      setFormData(nextData);
      navigate(
        `/journey/${sections[newActiveIndex + 1].name?.replace(/\s+/g, '-')?.toLowerCase()}-${
          newActiveIndex + 1
        }?${urlParams.toString()}`,
      );
      setActiveIndex(newActiveIndex + 1);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit a new quote
  // -----------------------------------------------------------------------------------------------

  const submitNewQuote = (values: IStringIndex<any>) => {
    // NEW: If journey is in step-1 it calls /create to create a new quote with no premium
    const step1 = activeIndex === 0;
    const nextSection = config.sections[activeIndex + 1];
    if (step1 && config?.APIs?.new) {
      setBusy(true);
      const apiConfig = config?.APIs?.new;
      if (nextSection?.isQuote || nextSection?.isQuoteListing) {
        apiConfig.payload.quote.isQuote = true;
      }
      if (nextSection.isQuoteListing) {
        setFetchingQuotes(true);
      }
      createQuote(config, config.APIs.new, values, props.branding)
        .then((results) => {
          handleNewQuote(results.data);
        })
        .catch((error: any) => {
          console.log('ERROR: ', error.message);
          setSubmitAlert({
            body: 'There was a problem creating a quote, please wait a moment and try again.',
            severity: 'error',
          });
          return;
        })
        .finally(() => {
          setBusy(false);
          setFetchingQuotes(false);
        });
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote update (patch or bound)
  // -----------------------------------------------------------------------------------------------

  const submitQuoteUpdate = async (values: IStringIndex<any>) => {
    // If we are on the final section, this will initiate BOUND.
    // Otherwise, it's just a regular old PATCH.
    if (section === finalSection && !section?.isPayment) {
      submitQuoteBound(values);
    } else if (section?.isPayment && values.paymentFrequency.toUpperCase() !== 'MONTHLY') {
      submitQuoteBound(values);
    } else {
      submitQuotePatch(values);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote bound
  // -----------------------------------------------------------------------------------------------

  const submitQuoteBound = (values: IStringIndex<any>) => {
    const nextSection = config.sections[activeIndex + 1];
    const indexOfQuoteListingSection = config.sections?.findIndex((s: TJourneySection) => s?.isQuoteListing);
    const postQuoteListing = indexOfQuoteListingSection > 0 && indexOfQuoteListingSection <= activeIndex;
    if (postQuoteListing) {
      values['sequenceId'] =
        formData?.values?.quote?.appliedQuoteDetails?.sequenceId ?? selectedAppliedQuote?.sequenceId;
      values['schemeRef'] = selectedAppliedQuote?.schemeRef;
      values['insurerId'] = selectedAppliedQuote?.insurerId ?? formData?.values?.quote?.appliedQuoteDetails?.insurerId;
    }
    if (config.APIs?.bound) {
      setBusy(true);
      patchQuote(config, config.APIs.bound, values)
        .then((result) => handleQuoteBound(result))
        .catch((err) => console.log(err));
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote patch
  // -----------------------------------------------------------------------------------------------

  const submitQuotePatch = (values: IStringIndex<any>) => {
    const nextSection = config.sections[activeIndex + 1];
    const performChecks = config.sections[activeIndex]?.performChecks;
    if (config.APIs?.patch) {
      const apiConfig = config.APIs.patch;
      if (nextSection.isQuoteListing && !disablePrePayment) {
        setFetchingQuotes(true);
      }
      if (nextSection.isQuote || nextSection?.quoteListing || Number(values.quote.premium) > 0) {
        apiConfig.payload.quote.isQuote = true;
      }
      if (performChecks) {
        apiConfig.payload.quote.performChecks = true;
      } else {
        apiConfig.payload.quote.performChecks = false;
      }
      setBusy(true);
      let appliedTransaction: AppliedTransactions | undefined = undefined;
      if (nextSection?.isQuoteListing && !disablePrePayment && mode !== 'MTA') {
        appliedTransaction = 'comparativeQuotes';
        updateSelectedQuote(null);
      } else {
        const indexOfQuoteListingSection = config.sections?.findIndex((s: TJourneySection) => s?.isQuoteListing);
        const postQuoteListing =
          indexOfQuoteListingSection > 0 && (indexOfQuoteListingSection <= activeIndex || disablePrePayment);
        values['sequenceId'] =
          formData?.values?.quote?.appliedQuoteDetails?.sequenceId ?? selectedAppliedQuote?.sequenceId;
        values['insurerId'] =
          formData?.values?.insurerId ??
          formData?.values?.quote?.appliedQuoteDetails?.insurerId ??
          selectedAppliedQuote?.insurerId;
        if (postQuoteListing) {
          if (selectedAppliedQuote) {
            values['schemeRef'] = selectedAppliedQuote?.schemeRef;
          }
          appliedTransaction = 'quoteDetails';
        }
      }
      patchQuote(config, config.APIs.patch, values, appliedTransaction)
        .then((result) => {
          const pipelineStage = getPipelineStage(activeIndex, sections);
          updateQuoteStage(values.quote.quoteId, pipelineStage)
            .then((res) => console.log(res))
            .catch((err) => console.error(err.message));
          handleQuotePatch(result);
        })
        .catch((err) => {
          console.error(err.message);
          setSubmitAlert({
            body: `An error occurred while submitting quote. Please try again later.`,
            severity: 'error',
          });
        })
        .finally(() => {
          setBusy(false);
          setFetchingQuotes(false);
        });
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote MTA
  // -----------------------------------------------------------------------------------------------

  const submitQuoteMTA = (values: IStringIndex<any>) => {
    let newActiveIndex = handleDynamicSections(
      sections,
      activeIndex,
      formData,
      'forward',
      props.mode,
      isPaymentSucceeded,
      disablePrePayment,
    );
    const nextSection = config.sections[newActiveIndex + 1];
    const goCardlessPolicy =
      values.quote.paymentFrequency.toUpperCase() !== 'MONTHLY' && values.quote.paymentProvider === 'GoCardless';
    const useOldQP = goCardlessPolicy ? false : !values?.quote?.useNewMtaQp;
    // Get MTA preview if next section is quote screen.
    // Only perform the actual MTA on the final section.
    if ((nextSection?.isQuote || nextSection?.performChecks || section?.performChecks) && config.APIs?.mta) {
      console.log('mta_preview');
      setBusy(true);
      const apiCopy = JSON.parse(JSON.stringify(config.APIs?.mta));
      apiCopy.payload.quote.journey = 'mta_preview';
      const performChecks = section?.performChecks;
      if (performChecks) {
        apiCopy.payload.quote.performChecks = true;
      } else {
        apiCopy.payload.quote.performChecks = false;
      }
      patchQuote(config, apiCopy, values)
        .then((result) => handleQuotePreviewMTA(result))
        .finally(() => setBusy(false));
    } else if (nextSection?.isPayment && useOldQP && config.APIs?.mta) {
      console.log('MTA::Old quote processor');
      setBusy(true);
      patchQuote(config, config.APIs.mta, values)
        .then((result) => handleQuoteMTA(result))
        .finally(() => setBusy(false));
    } else if ((section === finalSection || section?.isPayment) && config.APIs?.mta) {
      console.log('MTA::New quote processor');
      setBusy(true);
      patchQuote(config, config.APIs.mta, values)
        .then((result) => handleQuoteMTA(result))
        .finally(() => setBusy(false));
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of posting new quote
  // -----------------------------------------------------------------------------------------------

  const handleNewQuote = (result: any) => {
    if (result === null || result?.errorMessage || !Array.isArray(result)) {
      setSubmitAlert({
        body: 'There was a problem getting your quote, please wait a moment and try again.',
        severity: 'error',
      });
      return;
    }

    const isQuoteListing = sections[activeIndex + 1]?.isQuoteListing ?? sections[activeIndex]?.isQuoteListing;

    let quote = null;
    if (result.length === 1) {
      quote = result[0];
    } else {
      // Do something here? What exactly
      return;
    }
    if (quote) {
      if (isQuoteListing) {
        if (!quote?.comparativeQuotes || quote?.comparativeQuotes?.length === 0) {
          setQuoteListingError(true);
        }
      }

      const newIndex = activeIndex + 1;
      const nextSection = sections[newIndex];
      const nextData: TFormData = getFormData(nextSection.fields, formData, mode, config);
      setFormData({
        validations: { ...nextData.validations },
        values: { ...nextData.values, quote },
      });
      // we don't want to go to next section if current is quote listing and calling create quote
      if (!sections[activeIndex]?.isQuoteListing) {
        setActiveIndex(newIndex);
        navigate(
          `/journey/${sections[newIndex].name
            ?.replace(/\s+/g, '-')
            ?.toLowerCase()}-${newIndex}?${urlParams.toString()}`,
        );
      }
    } else {
    }
    // Do something here? What exactly
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of patching a quote
  // -----------------------------------------------------------------------------------------------

  const handleQuotePatch = (result: any) => {
    if (result?.errorType === 'POLICY_REFERRED') {
      setSubmitAlert({
        body: 'Your policy has been referred and one of our internal team members will get in touch with you shortly',
        severity: 'info',
      });
      setJourneyLocked(true);
      return;
    }

    if (result?.errorType === 'CHECK_FAILED') {
      setSubmitAlert({
        body: result?.errorMessage ?? 'There was a problem updating your quote, please wait a moment and try again.',
        severity: 'error',
      });
      setJourneyLocked(true);
      return;
    }

    if (result === null || result?.errorMessage || !Array.isArray(result) || result.length !== 1) {
      setSubmitAlert({
        body: 'There was a problem updating your quote, please wait a moment and try again.',
        severity: 'error',
      });
      return;
    }

    const isQuoteListingNext = sections[activeIndex + 1]?.isQuoteListing;

    const quote = result[0];

    if (isQuoteListingNext) {
      if (!quote?.comparativeQuotes || quote?.comparativeQuotes?.length === 0) {
        setQuoteListingError(true);
      }
    }

    if (quote.state === 'DECLINED') {
      setSubmitAlert({
        body: `Declined: ${quote.rejectionReasons[0].message} - ${quote.rejectionReasons[0].value}`,
        severity: 'error',
      });
      return;
    }
    let newActiveIndex = handleDynamicSections(
      sections,
      activeIndex,
      formData,
      'forward',
      props.mode,
      isPaymentSucceeded,
      disablePrePayment,
    );
    const newIndex = newActiveIndex + 1;
    const nextSection = sections[newIndex];
    const nextData: TFormData = getFormData(nextSection.fields, formData, mode, config);
    setFormData({
      validations: { ...nextData.validations },
      values: { ...nextData.values, quote },
    });
    setActiveIndex(newIndex);
    navigate(
      `/journey/${sections[newIndex].name?.replace(/\s+/g, '-')?.toLowerCase()}-${newIndex}?${urlParams.toString()}`,
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of binding a quote
  // -----------------------------------------------------------------------------------------------

  const handleQuoteBound = (result: any) => {
    if (
      result === null ||
      result?.pcError ||
      result?.errorMessage ||
      result?.error ||
      !Array.isArray(result) ||
      result.length !== 1
    ) {
      setBusy(false);
      setSubmitAlert({
        body:
          result?.error ||
          result?.pcError ||
          'There was a problem processing your quote, please wait a moment and try again.',
        severity: 'error',
      });
      return;
    }
    const r = Array.isArray(result) ? result[0] : result;
    if (config?.payment?.provider === 'stripe') {
      //window.location.replace(window.location.origin + "/?user_session_token=" + r.quoteId);
      props.setSuccessToken(r.quoteId);
    } else if (r.redirectUrl) {
      window.location.replace(r.redirectUrl);
    } else if (r.policyNumber) {
      setNFResponse(r);
      setBusy(false);
    } else {
      alert('No redirect url?');
      setBusy(false);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of performing MTA
  // -----------------------------------------------------------------------------------------------

  const handleQuoteMTA = (result: any) => {
    if (result === null || result?.errorMessage || !Array.isArray(result) || result.length !== 1) {
      setSubmitAlert({
        body: 'There was a problem performing the MTA, please wait a moment and try again.',
        severity: 'error',
      });
      return;
    }
    setFormData({
      validations: { ...formData.validations },
      values: { ...formData.values, MTA: result[0] },
    });
    setShowMTASuccess(true);
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of performing MTA preview
  // -----------------------------------------------------------------------------------------------

  const handleQuotePreviewMTA = (result: any) => {
    if (result?.errorType === 'MTA_FAILED') {
      setSubmitAlert({
        body: 'There was a problem updating your quote, please wait a moment and try again.',
        severity: 'error',
      });
      setAppliedMTAError(true);
      return;
    }
    if (result?.errorType === 'POLICY_REFERRED') {
      setSubmitAlert({
        body: 'Your policy has been referred and one of our internal team members will get in touch with you shortly',
        severity: 'info',
      });
      setJourneyLocked(true);
      return;
    }

    if (result?.errorType === 'CHECK_FAILED') {
      setSubmitAlert({
        body: result?.errorMessage ?? 'There was a problem updating your quote, please wait a moment and try again.',
        severity: 'error',
      });
      setJourneyLocked(true);
      return;
    }
    if (
      result === null ||
      result?.errorMessage ||
      // result?.statusCode !== 200 ||
      !Array.isArray(result) ||
      result.length !== 1
    ) {
      const errorMessage = result?.errorMessage?.includes(`AgreementHasUngeneratedBatches`)
        ? `Unable to perform MTA at this time due to current Agreement has ungenerated batches. Please try again later.`
        : `There was a problem previewing the MTA, please wait a moment and try again.`;
      setSubmitAlert({
        body: errorMessage,
        severity: 'error',
      });
      return;
    }
    prepNextSection({ ...formData.values, MTA: result[0] }, sections[activeIndex + 1]);
  };

  // -----------------------------------------------------------------------------------------------
  // Navigation button handlers
  // -----------------------------------------------------------------------------------------------

  const getBackHandler = () => {
    if (mode === 'MTA' || mode === 'RESUME') {
      if (activeIndex > 0) {
        return handleBack;
      } else {
        return undefined;
      }
    }
    return handleBack;
  };

  const getForwardHandler = () => {
    if (mode === 'TEST' || mode === 'EDIT') {
      return handleForward;
    }
    return undefined;
  };

  const handleBack = () => {
    if (busy) {
      return;
    }
    if (paymentConfirmed || journeyLocked) {
      return;
    }
    if (section?.isPayment && formData.values.quote?.paymentDetails?.status === 'succeeded') {
      return;
    }
    if (submitAlert) {
      setSubmitAlert(undefined);
    }
    let newActiveIndex = handleDynamicSections(
      sections,
      activeIndex,
      formData,
      'backward',
      props.mode,
      isPaymentSucceeded,
      disablePrePayment,
    );

    const newIndex = newActiveIndex - 1;
    if (newIndex >= 0) {
      navigate(
        `/journey/${sections[newIndex].name?.replace(/\s+/g, '-')?.toLowerCase()}-${newIndex}?${urlParams.toString()}`,
      );
      setActiveIndex(newIndex);
    } else if (props.setActiveJourney) {
      navigate('/');
      props.setActiveJourney(null);
    }
  };

  const handleForward = () => {
    let newActiveIndex = handleDynamicSections(
      sections,
      activeIndex,
      formData,
      'forward',
      props.mode,
      isPaymentSucceeded,
      disablePrePayment,
    );
    const newIndex = newActiveIndex + 1;
    if (newIndex >= sections.length) {
      return;
    }
    const nextSection = sections[newIndex];
    const nextData: TFormData = getFormData(nextSection.fields, formData, mode, config);
    setFormData(nextData);
    setActiveIndex(newIndex);
    navigate(
      `/journey/${sections[newIndex].name?.replace(/\s+/g, '-')?.toLowerCase()}-${newIndex}?${urlParams.toString()}`,
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Rendering helpers
  // -----------------------------------------------------------------------------------------------

  const visibleFields = section?.fields.filter((f) => !f.hidden) || [];
  let allValid = true;
  for (let f of visibleFields) {
    if (f.type === 'group') {
      const fields = f?.fields ?? [];
      for (const groupField of fields) {
        if (!formData.validations[groupField.name] && !groupField.hidden) {
          allValid = false;
          break;
        }
      }
    }
    if (!formData.validations[f.name]) {
      allValid = false;
      break;
    }
  }

  const renderSubmitAlert = () => {
    if (!submitAlert) {
      return;
    }
    if (config?.renderVersion === 'V2') {
      return (
        <CustomAlert
          title={submitAlert.title}
          severity={submitAlert.severity}
          sx={{ marginTop: 2, border: '1px solid rgba(0,0,0,0.5)' }}
          onClose={() => setSubmitAlert(undefined)}
        >
          {submitAlert.body}
        </CustomAlert>
      );
    }
    return (
      <Alert
        severity={submitAlert.severity}
        sx={{ marginTop: 2, border: '1px solid rgba(0,0,0,0.5)' }}
        onClose={() => setSubmitAlert(undefined)}
      >
        {submitAlert.title && <AlertTitle>{submitAlert.title}</AlertTitle>}
        {submitAlert.body}
      </Alert>
    );
  };

  const renderNFSuccess = () => {
    return (
      <Dialog open={true} scroll="paper" fullWidth PaperProps={{ sx: { width: '100%', margin: '0px' } }}>
        <DialogTitle>Congrats {NFResponse.proposerName}!</DialogTitle>
        <DialogContent className="dialogContent">
          <Confetti />
          <Stack spacing={3}>
            <Alert severity="success" sx={{ border: '1px solid rgba(0,0,0,0.5)', width: '100%' }}>
              {<AlertTitle>Payment Confirmed</AlertTitle>}
              <Stack spacing={2}>Your payment details were confirmed successfully!</Stack>
            </Alert>
            <p>
              You have now submitted your {config.product.title} application and direct debit details, National Friendly
              will be in touch soon.
            </p>
            <p>
              Your application number is <b>{NFResponse.policyNumber}</b>
            </p>
            <p>
              A welcome email will be sent to <b>{NFResponse.proposerEmail}</b>
            </p>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  const quoteIndex = sections?.findIndex((s: any) => s.isQuote === true);
  const finalIndex = sections?.findIndex((s: any) =>
    config.payment?.provider === 'stripe'
      ? formData.values?.paymentFrequency === 'MONTHLY'
        ? s.isFinal === true
        : s.isPayment === true
      : s.isFinal === true,
  );
  const paymentIndex = sections?.findIndex((s: any) => s.isPayment === true);
  const quoteListingIndex = sections?.findIndex((s: any) => s.isQuoteListing === true);

  const isFieldsLocked = formData.values?.quote?.lockFields;
  const isPaymentSucceeded = formData.values?.paymentIntent?.status === 'succeeded';
  const isPaymentPending = formData.values?.paymentIntent?.status === 'requires_payment_method';
  const isQuoteStateQuote = formData.values?.quote?.state === 'QUOTE';

  const disablePrePayment =
    isFieldsLocked || (activeIndex < paymentIndex && (isPaymentSucceeded || isPaymentPending) && isQuoteStateQuote);

  const disableAllFields = busy || disablePrePayment || journeyLocked;

  let height;
  if (productsLoading) {
    height = '100vh';
  } else {
    height = '';
  }

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundColor: quoteListingError || appliedMTAError ? '#FFFF' : '#F2F2FF',
      }}
    >
      <div style={{ flex: 1 }}>
        <JourneyContext.Provider
          value={{
            formData,
            setFormData,
            mode,
            branding,
            activeIndex,
            setActiveIndex,
            selectedAppliedQuote: undefined,
            valid: allValid,
          }}
        >
          {showMTASuccess && <MTASuccessPage config={config} MTA={formData.values.MTA || null} />}
          {!isEmbedded && props.branding && (
            <Branding
              branding={props.branding}
              productsLoading={productsLoading}
              onBack={getBackHandler()}
              onForward={getForwardHandler()}
            />
          )}
          <JourneyHeader
            branding={props.branding}
            productsLoading={productsLoading}
            index={activeIndex}
            section={section}
            formValues={formData.values}
            config={config}
            disabled={busy || disableAllFields}
          />
          {NFResponse && renderNFSuccess()}
          {
            <>
              <QuoteListingLoader open={fetchingQuotes} onClose={() => {}} />
              {section?.name === 'quoteListing' || appliedMTAError ? (
                appliedMTAError ? (
                  <QuoteListingError isMTA backHandler={() => setAppliedMTAError(false)} />
                ) : (
                  <Routes>
                    <Route
                      path={`/journey/${sections[quoteListingIndex].name
                        ?.replace(/\s+/g, '-')
                        ?.toLowerCase()}-${quoteListingIndex}`}
                      element={
                        <QuoteListingSection
                          disablePrePayment={disablePrePayment}
                          setFormData={setFormData}
                          setQuoteListingError={setQuoteListingError}
                          activeIndex={activeIndex}
                          branding={branding}
                          submitError={submitAlert}
                          quoteListingError={quoteListingError ?? false}
                          isSubmitting={busy}
                          handleSubmit={handleSubmit}
                          backHandler={getBackHandler()}
                          formData={formData}
                          config={config}
                          changeHandler={changeHandler}
                        />
                      }
                    />
                  </Routes>
                )
              ) : (
                <>
                  <Container
                    maxWidth="sm"
                    style={{ height }}
                    className={config?.renderVersion === 'V2' ? 'journeyContainerV2' : 'journeyContainer'}
                  >
                    {!productsLoading ? (
                      <Routes>
                        {sections?.map((s: any, i: number) => (
                          <>
                            {[quoteListingIndex].includes(i) && null}
                            <Route
                              key={s.name}
                              path={`/journey/${sections[i].name
                                ?.replace(/\s+/g, '-')
                                ?.toLowerCase()
                                ?.replace(/\s+/g, '-')
                                ?.toLowerCase()}-${i}`}
                              element={
                                <JourneySection
                                  setFormData={setFormData}
                                  submitQuoteUpdate={submitQuoteUpdate}
                                  mode={mode}
                                  config={config}
                                  section={s}
                                  formData={formData}
                                  changeHandler={changeHandler}
                                  disabled={disableAllFields}
                                  indexes={{
                                    quote: quoteIndex,
                                    active: i,
                                    final: finalIndex,
                                  }}
                                  paymentComponentRef={paymentComponentRef}
                                  setBusy={setBusy}
                                  onBack={isEmbedded ? getBackHandler() : undefined}
                                />
                              }
                            />
                          </>
                        ))}
                      </Routes>
                    ) : (
                      <LoadingIndicator />
                    )}
                    {section && submitAlert && renderSubmitAlert()}
                  </Container>
                  {section && !section?.hideSubmitBtn && (
                    <SubmitButton
                      renderVersion={config?.renderVersion}
                      key={section.name}
                      onSubmit={handleSubmit}
                      label={getSubmitBtnLabel(section, mode, formData)}
                      disabled={!allValid || journeyLocked}
                      loading={busy}
                    />
                  )}
                </>
              )}
            </>
          }
          {(mode === 'TEST' || mode === 'EDIT') && <DebugView config={config} formData={formData} />}
        </JourneyContext.Provider>
      </div>
      {config?.useDynamicFooter && <DynamicFooter config={config} formData={formData} branding={branding} />}
      {!isEmbedded && <RegulatoryFooter config={config} formData={formData} />}
    </div>
  );
};

function handleDynamicSections(
  sections: any,
  activeIndex: number,
  formData: TFormData,
  nextDirection: 'forward' | 'backward',
  mode: TJourneyMode,
  isPaymentSucceeded: boolean,
  disablePrePayment: boolean,
) {
  const n = nextDirection === 'forward' ? 1 : -1;
  const nextSection = sections[activeIndex + n];
  const hasConditions = nextSection?.dependsOn;
  let newActiveIndex = activeIndex;

  if (hasConditions) {
    const conditionsMet = conditionMet(nextSection?.dependsOn, formData.values, formData.validations);
    if (!conditionsMet) {
      newActiveIndex = activeIndex + n;
    }
  } else if (nextSection?.name === 'monthlyPaymentConfirmation') {
    if (formData?.values?.paymentFrequency?.toLowerCase() !== 'monthly') {
      newActiveIndex = activeIndex + n;
    }
  } else if (nextSection?.isQuoteListing && (mode === 'MTA' || isPaymentSucceeded || disablePrePayment)) {
    newActiveIndex = activeIndex + n;
  }
  return newActiveIndex;
}

function getSubmitBtnLabel(section: TJourneySection, mode: string, formData: TFormData): string {
  if (mode === 'MTA' && section?.submitLabel?.toLocaleLowerCase() === 'get quotes') {
    return 'Get quote';
  }

  if (mode === 'MTA' && (section.isFinal || section.isPayment)) {
    return 'Perform MTA';
  }

  if (section?.isPayment && formData?.values?.paymentFrequency?.toLowerCase() === 'monthly') {
    return `Pay deposit`;
  }

  return replacePlaceholders(formData.values?.quote, section.submitLabel || 'Submit');
}

function preprocessInitialValues(initialValues: IStringIndex<any>) {
  for (let [fieldName, fieldValue] of Object.entries(initialValues)) {
    if (typeof fieldValue === 'string') {
      if (fieldValue.startsWith('$NOW')) {
        const dateFormat = fieldValue.match(/\((.*?)\)/);
        const today = moment().format(dateFormat ? dateFormat[1] : 'DD-MM-YYYY');
        initialValues[fieldName] = today;
      }
    }
  }
}

function getPipelineStage(activeIndex: number, sections: TJourneySection[]) {
  enum PipelineStages {
    LeadCaptured = 'Lead Captured',
    MinimumQuestionsToQuote = 'Minimum Questions to quote',
    Quote = 'Quote',
    ExclusionsUnderwriting = 'Exclusion & underwriting',
    Finalisation = 'Finalisation',
  }
  const quoteListingIndex = sections?.findIndex((s) => s.isQuoteListing === true);
  const quoteIndex = sections?.findIndex((s) => s.isQuote === true);
  const finalIndex = sections?.findIndex((s) => s.isFinal === true);
  const paymentIndex = sections?.findIndex((s) => s?.isPayment === true);
  const section = sections[activeIndex];

  let stage: PipelineStages = PipelineStages.LeadCaptured;

  if (!section) return stage;

  switch (activeIndex) {
    case 0:
      stage = PipelineStages.LeadCaptured;
      break;
    case quoteIndex:
    case quoteListingIndex:
      stage = PipelineStages.Quote;
      break;
    case finalIndex:
    case paymentIndex:
      stage = PipelineStages.Finalisation;
      break;
    default:
      const finalSections = [
        'policyDatesDetails',
        'addressDetails',
        'dateOfBirth',
        'Bill payer',
        'Bank Details',
        'Final Summary',
        'dateOfBirth',
        'addressDetails',
      ];
      if (finalSections.includes(section.name)) {
        stage = PipelineStages.Finalisation;
      } else if (activeIndex < quoteIndex) {
        stage = PipelineStages.MinimumQuestionsToQuote;
      } else if (activeIndex < finalIndex) {
        stage = PipelineStages.ExclusionsUnderwriting;
      }
      break;
  }

  // console.log(`STAGE => ${stage} on ${section.name}`);
  return stage;
}
