import { createRoot } from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Cookies from 'js-cookie';

import Home from './pages/Home';
import './index.css';
import ProductsContextProvider from './ProductsApiContext';
import PaymentContextProvider from './PaymentContext';
import { ErrorBoundary } from 'react-error-boundary';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const cookieBranding = Cookies.get('branding');
const fetchedBranding = cookieBranding && JSON.parse(decodeURIComponent(cookieBranding as string));

const container = document.getElementById('wljContainer')!;
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <ErrorBoundary
    fallback={
      <div style={{ color: 'red', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        Something went wrong
      </div>
    }
  >
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ProductsContextProvider>
          <PaymentContextProvider>
            <Routes>
              <Route path="*" element={<Home fetchedBranding={fetchedBranding} />} />
            </Routes>
          </PaymentContextProvider>
        </ProductsContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </ErrorBoundary>,
);
