import moment from 'moment';

// This is to prefill stupid journeys like applied. only works locally or on dev
export default {
  'pro-79219268-f1cf-47a5-9203-7714fabe1ff4': {
    paymentFrequency: 'YEARLY',
    acceptTermsOfUse: false,
    tyreType: 'nonRunFlats',
    coverDuration: '12',
    coverLimit: '125',
    vehicleUsage: 'No',
    PolicyDocumentsDisclaimer: true,
    Title: 'Mr',
    firstName: 'sohail',
    lastName: 'gsais',
    telephoneNumber: '07000000000',
    emailAddress: 'sohail.gsais@stubbenedge.com',
    currentMileage: 23333,
    purchasedFrom: 'Dealer / Online Broker',
    vehicle: {
      vehicle_make: 'MERCEDES-BENZ',
      vehicle_model: 'GLC 250 D 4M AMG LINE PREM A',
      vehicle_make_model: 'MERCEDES-BENZ GLC 250 D 4M AMG LINE PREM A',
      vehicle_range: 'GLC',
      vehicle_trim: 'GLC 250 D 4MATIC AMG LINE PREMIUM',
      vehicle_year: '2018',
      vehicle_colour: 'BLACK',
      num_of_doors: '5',
      num_of_seats: '5',
      body: 'SUV',
      engineCC: '2143',
      fuel_type: 'Diesel',
      transmission: 'Automatic',
      registration_date: '29-03-2018',
      registration_year: '2018',
      registration_number: 'BG18MTU',
      manufactureYear: '2018',
      driverPosition: 'R',
      abiBrokerNetCode: '32131851',
      age: '6',
    },
    dateOfBirth: '05-10-2006',
    address: {
      line1: '77 Cornhill',
      line2: '',
      town: 'London',
      county: '',
      postcode: 'EC3V 3QQ',
    },
    coverStartDate: '22-10-2024',
    confirmSummary: true,
  },
  'pro-cdd1a60c-0d6e-4b22-ae42-491a9ddfe087': {
    addressDetails: {
      county: '',
      line1: '3a Strand Parade, The Boulevard',
      line2: 'Goring-by-sea',
      postcode: 'BN12 6DH',
      town: 'Worthing',
    },
    annualPersonalMileage: 10000,
    coverCode: '01',
    coverStartDate: moment(new Date(), 'DD/MM/YYYY'),
    currentMileage: 10000,
    dateOfBirth: '11/06/1972',
    disabledBadgeHolder: false,
    emailAddress: 'russellc@stubbenedge.com',
    employmentType: 'E',
    estimatedValue: 5000,
    everHadPolicyCancelled: false,
    firstName: 'John',
    fullTimeEmployment: true,
    gender: 'M',
    hadQPlate: false,
    hasAdditionalDrivers: false,
    hasMedicalConditions: false,
    hasNonMotoringCriminalConvictions: false,
    hasPrevClaims: false,
    hasProsecutionPending: false,
    hasUnspentMotoringConvictions: false,
    homeOwner: false,
    licenceHasRestrictions: false,
    hasAddQualifications: false,
    isModified: false,
    isSecurityDeviceFitted: false,
    keeper: '1',
    lastName: 'Deltest',
    licenceNumber: '',
    licenceNumberChecked: true,
    licenseCountryOfIssue: '023',
    licenseDate: '12/06/2017',
    licenseType: 'F',
    locationKeptOvernight: 'Q',
    locationKeptOvernightAddress: {
      county: '',
      line1: '3a Strand Parade, The Boulevard',
      line2: 'Goring-by-sea',
      postcode: 'BN12 6DH',
      town: 'Worthing',
    },
    maritalStatus: 'S',
    ncdClaimedEntitlementReason: '11',
    ncdClaimedProtectionRequired: false,
    ncdClaimedYears: 5,
    nominalCode: '21074',
    noOfVehicles: 1,
    occupationCode: 'A01',
    owner: '1',
    paymentFrequency: 'YEARLY',
    permanentResident: true,
    postcodeSameAsVehicleLocation: true,
    prevAppliedIncPremium: false,
    previouslyInsured: true,
    prevExpiryDate: '09-07-2024',
    prevInsr: '997',
    prevInsrChecked: true,
    prevPaymentRegularity: '03',
    prevRestrictiveTermsApplied: false,
    purchaseDate: '05/2023',
    refusedCover: false,
    regdDisabled: false,
    smoker: false,
    telNumber: '08888888888',
    title: '003',
    trade: '001',
    typeOfDwelling: '01',
    ukResidencyDate: '11/06/1972',
    ukResidencyDateChecked: true,
    vehicle: {
      abiBrokerNetCode: '52326852',
      age: '2',
      body: 'Hatchback',
      driverPosition: 'R',
      engineCC: '1199',
      fuel_type: 'Petrol',
      manufactureYear: '2022',
      num_of_doors: '5',
      num_of_seats: '5',
      registration_date: '12-04-2022',
      registration_number: 'KM22KHA',
      registration_year: '2022',
      transmission: 'Manual',
      vehicle_colour: 'BLACK',
      vehicle_make: 'VAUXHALL',
      vehicle_make_model: 'VAUXHALL CORSA ELITE EDITION',
      vehicle_model: 'CORSA ELITE EDITION',
      vehicle_range: 'Corsa',
      vehicle_trim: 'N/A',
      vehicle_year: '2022',
    },
    vehicleImported: false,
    vehicleUse: '04',
    voluntaryExcessAmount: 200,
    noOfOtherVehiclesOwned: 0,
    noOfOtherPolicies: 0,
  },
  'pro-df948c5a-bfd8-4ddb-9a40-ce3079b9925a': {
    address: {
      line1: '4 St. Peters Close',
      line2: '',
      postcode: 'DA10 0BD',
      town: 'Swanscombe',
    },
    AWNSI2: 100,
    compulsoryExcess: 100,
    CONTSI1: 1000,
    dateOfBirth: '29-06-2006',
    emailAddress: 'kazeem.alatishe@stubbenedge.com',
    firstName: 'fdsfsfsd',
    FLOODHIST: 'false',
    FLOODLOC: 'false',
    hitchAndWheel: 'true',
    INSHISTDEC1: 'true',
    lastName: 'rge',
    paymentFrequency: 'YEARLY',
    publicLiabilityLimitOfIndemnity: '£2,000,000',
    SECQUEST1: 'false',
    SECQUEST2: 'None',
    SECQUEST3: 'false',
    SECQUEST4: 'None',
    SECQUEST5: [
      {
        label: 'Stop Theft tagging kit/Datatag',
        value: 'Stop Theft tagging kit/Datatag',
      },
    ],
    SITEADD1: {
      line1: '4 St. Peters Close',
      line2: '',
      postcode: 'DA10 0BD',
      town: 'Swanscombe',
    },
    telephoneNumber: '07956787654',
    Title: 'Mr',
    TOURCLAIMS1: 'false',
    TOURHEAT1: 'false',
    TOURHEAT2: 'false',
    TOURINT1: '',
    TOURMAKE1: 'Abbey',
    TOURMATFACT1: '',
    TOURMOD1: 'Kazeem',
    TOURNCD1: '1',
    TOURREPAIR1: 'true',
    TOURSER1: '',
    TOURSI1: 20000,
    TOURSI2: 'true',
    TOURSITE1: 'At home on a driveway - within garden boundaries',
    TOURSITE2: 'None',
    TOURSITE3: 'true',
    TOURSITE4: 'true',
    TOURSITE5: 'false',
    TOURTYPE: 'Tourer - Single Axle',
    TOURUSE1: 'Personal / Holiday Use Only',
    TOURXS1: '£150',
    yearOfManufacture: '2023',
  },
  'pro-9dc2ce4a-73a8-4f32-a55f-df022f1275f6': {
    paymentFrequency: 'MONTHLY',
    acceptTermsOfUse: true,
    dateOfBirth: '17-10-2006',
    yourGender: 'Male',
    coverStartDate: '22-10-2024',
    occupationStatus: 'Employed',
    occupation: '212dbc52-c13c-4565-993f-0046a4a87321',
    annualGrossIncome: 23333,
    mainMonthlyBenefit: 555,
    trackInflationRateAndIncreaseByRPI: 0,
    monthlyBenefitGuaranteed: false,
    accidentalDeathBenefit: false,
    hazardousPursuits: false,
    policyEndAge: 61,
    benefitPeriod: 2,
    deferredPeriod: 8,
    DoctorName: 'test',
    SurgeryName: 'test',
    DoctorPhone: '07000000000',
    DoctorAddress: {
      line1: 'Flat 3, The Powerhouse',
      line2: '74 Cornhill',
      town: 'London',
      county: '',
      postcode: 'EC3V 3QQ',
    },
    billPayerSignature: 'test',
    PolicyDocumentsDisclaimer: true,
    Title: 'Mr',
    firstName: 'sohail',
    lastName: 'gsais',
    telephoneNumber: '07000000000',
    emailAddress: 'sohail.gsais@stubbenedge.com',
    acceptDataProtectionNotes: true,
    acceptDeclarationNotes: true,
    welcomePack: 'Email',
    threeYearsUKGP: 'Yes',
    address: {
      line1: 'Flat 3, The Powerhouse',
      line2: '74 Cornhill',
      town: 'London',
      county: '',
      postcode: 'EC3V 3QQ',
    },
    billPayerDifferent: false,
    directDebit: {
      paperMandate: true,
    },
  },
} as Record<string, any>;
