import React, { useEffect, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { TFieldChangeHandler, TFormData, TFormField, TJourneyConfig } from '../../types';
import { InputAdornment, TextField } from '@mui/material';
import { replacePlaceholders } from '../../formHandling';

export default function CalculatedField(props: {
  field: TFormField;
  value: any;
  config: TJourneyConfig;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
  busy: boolean;
}) {
  const { field, value, formData, changeHandler, busy } = props;
  const [validationError, setValidationError] = useState<string | undefined>();

  const evaluate = (): number | null => {
    try {
      // Allow only numbers, arithmetic operators, parentheses, and whitespace
      const validChars = /^(Math\.min\(.*?\)|Math\.max\(.*?\)|[0-9+\-*/().\s]+|\$\{\w+\})+$/;

      const expression = replacePlaceholders(formData.values, field.params?.formula ?? '')
        ?.trim()
        ?.replaceAll('N/A', '0');
      if (!validChars.test(expression)) {
        throw new Error('Invalid characters in the expression');
      }

      // Create a function to safely evaluate the expression
      // This will only evaluate basic math operations and nothing else
      // eslint-disable-next-line no-new-func
      const result = new Function(`return (${expression})`)();

      // Ensure the result is a valid number
      if (typeof result === 'number' && !isNaN(result)) {
        return result;
      } else {
        throw new Error('Invalid math expression');
      }
    } catch (error: any) {
      console.error('Invalid field configurations', error.message);
      return null;
    }
  };

  const localChangeHandler = (newValue: number) => {
    changeHandler(field, newValue);
  };

  useEffect(() => {
    if (field?.params?.formula && !busy) {
      const result = evaluate();
      if (result !== null && (typeof value !== 'number' || value !== result)) {
        localChangeHandler(result);
      }
    }
  }, [formData.values]);

  return (
    <>
      <TextField
        autoComplete="autocomplete_off_randString"
        disabled={field.locked || field?.disabled}
        fullWidth
        placeholder={field?.title}
        value={value}
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          localChangeHandler(Number(event.target.value));
        }}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          startAdornment: field?.params?.adornment?.start && (
            <InputAdornment position="start">{field?.params?.adornment?.start}</InputAdornment>
          ),
          endAdornment: field?.params?.adornment?.end && (
            <InputAdornment position="end">{field?.params?.adornment?.end}</InputAdornment>
          ),
          inputComponent: NumericFormatCustom as any,
        }}
        helperText={validationError || ''}
        error={Boolean(validationError)}
      />
    </>
  );
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  clear: boolean;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, clear, ...other } = props;

  return (
    <NumericFormat
      {...other}
      autoComplete="autocomplete_off_randString"
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});
