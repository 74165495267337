import lodash from 'lodash';
import { DeploymentHost, IStringIndex, TEnvParams, TFormData, TFormField, TJourneyConfig, TJourneyMode } from './types';
import { getEnvironment } from './config';
import Cookies from 'js-cookie';

export const TouchDelay = 15; // 15 ms touch delay before showing tooltips on mobile. Tweak as needed.

export const getEnvParams = (): TEnvParams => {
  const container = document.getElementById('wljContainer')!;
  const urlParams = new URL(window.location.href);
  const embeddedParams = {
    uuid: container.getAttribute('uuid'),
    productId: container.getAttribute('productId'),
    hostname: container.getAttribute('hostname'),
  };
  const isEmbedded = Boolean(embeddedParams.uuid && embeddedParams.productId && embeddedParams.hostname);

  const mode =
    isEmbedded || !['localhost', 'development'].includes(getEnvironment(urlParams.hostname as DeploymentHost))
      ? null
      : urlParams.searchParams.get('mode');
  const prefill = !['localhost', 'development', 'segstaging'].includes(
    getEnvironment(urlParams.hostname as DeploymentHost),
  )
    ? false
    : JSON.parse(urlParams.searchParams.get('prefill') ?? 'false');

  const hostname = (isEmbedded ? embeddedParams.hostname ?? urlParams.hostname : urlParams.hostname) as DeploymentHost;

  if (mode?.toLowerCase() === 'test' && !Cookies.get('branding')) {
    Cookies.set(
      'branding',
      JSON.stringify({
        main_color: '#2741E6',
        website: null,
        address: '77 Cornhil, UNITED KINGDOM, EC3V 3QQ',
        documents: [],
        name: 'save more money',
        company_iocn:
          'https://seg-crm-production.s3.eu-west-1.amazonaws.com/companies/com-4f96d92f-2af6-405e-9a13-760b2f236f29/9QwjwKYHH7YqUEckL2Np8gA6rWCFuusQ7eoR83IY.png',
        full_logo:
          'https://seg-crm-production.s3.eu-west-1.amazonaws.com/companies/com-4f96d92f-2af6-405e-9a13-760b2f236f29/Fe50d18XVYc0887ehuhGuIwHNKuU7BOrXEQ7sUSO.png',
        servicing_email: 'hello@savemoremoney.com',
        uuid: 'com-4f96d92f-2af6-405e-9a13-760b2f236f29',
        servicing_phone: '02078469942',
        slug: 'save-more-money',
        regulatoryFooter: 'This is a test regulatory footer with dummy wording for Save More Money in development.',
      }),
    );
  }

  return {
    hostname,
    environment: getEnvironment(hostname),
    isEmbedded,
    uuid: isEmbedded ? embeddedParams.uuid : urlParams.searchParams.get('uuid'),
    productId: isEmbedded ? embeddedParams.productId : urlParams.searchParams.get('productId'),
    quoteId: isEmbedded ? null : urlParams.searchParams.get('quoteId'),
    userSessionToken: isEmbedded ? null : urlParams.searchParams.get('user_session_token'),
    mode: (mode ? mode.toUpperCase() : null) as TJourneyMode,
    sourceIdentifier: {
      utm_source: urlParams.searchParams.get('utm_source'),
      utm_medium: urlParams.searchParams.get('utm_medium'),
      utm_campaign: urlParams.searchParams.get('utm_campaign'),
      utm_term: urlParams.searchParams.get('utm_term'),
      utm_content: urlParams.searchParams.get('utm_content'),
    },
    prefill,
  } as TEnvParams;
};

export function getJSONProperty(obj: any, notation: string, debug = false) {
  if (debug) {
    console.log('getJSONProperty', JSON.stringify(obj, null, 2), notation);
  }
  return notation.split('.').reduce((a, b) => a[b], obj);
}

export const substituteForHeadings = (text: string, values: IStringIndex<string>, debug = false): string => {
  return text.replace(/\$\{[^}]+}/g, (match) => {
    const notation = match.substring(2, match.length - 1);
    const s = getJSONProperty(values, notation, debug);
    if (typeof s === 'string') {
      return toTitleCase(s);
    }
    return s || match;
  });
};

export const substituteVariables = (text: string, values: IStringIndex<string>): any => {
  const matches = text.matchAll(/\${[^}]+}/g);
  for (let m of matches) {
    const match = m[0];
    const notation = match.substring(2, match.length - 1);
    const t = text.substring(0, m.index);
    const v = lodash.get(values, notation); // ?? match;
    return t.length ? t + v : v;
  }
  return text;
};

export const populateTemplate = (template: any, values: IStringIndex<any>) => {
  if (!template) {
    return;
  }
  const templateCopy = JSON.parse(JSON.stringify(template)); // Make a deep copy
  const validatedValues = values;

  // Helper to go over all fields in an object structure and replace values
  const replaceValues = (obj: any) => {
    //console.log(replaceValues, obj);
    for (let [key, value] of Object.entries(obj)) {
      if (typeof value === 'object') {
        if (value === null) {
          obj[key] = null;
        } else {
          replaceValues(value);
        }
      } else {
        const replaced = typeof value === 'string' ? substituteVariables(value, validatedValues) : value;
        //console.log(`Setting ${key} to ${replaced} (${typeof replaced})`);
        obj[key] = replaced;
      }
    }
  };

  replaceValues(templateCopy);
  //console.log(JSON.stringify(templateCopy, null, 2));
  return templateCopy;
};

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getTitleFromKey(docKey: string) {
  const docParts = docKey.split('/');
  const dashesToSpace = dashesToSpaces(docParts[docParts.length - 1]).replace('.pdf', '');
  if (/\s\d+$/.test(dashesToSpace)) {
    return dashesToSpace.replace(/\s\d+$/, '');
  }
  return dashesToSpace;
}

export function dashesToSpaces(str: string) {
  return str.replaceAll('-', ' ');
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const findMatchingField = (allFields: TFormField[], fieldName: string): any => {
  for (const field of allFields) {
    if (field.type === 'group') {
      const matchingField = findMatchingField(field.fields || [], fieldName);
      if (matchingField) {
        return matchingField;
      }
    } else if (field.name === fieldName) {
      return field;
    }
  }
  return null;
};
export const unAnsweredFields = (formData: TFormData, fields?: TFormField[]) => {
  if (!fields) return null;

  const unAnswered: { question: string }[] = [];

  for (const field of fields) {
    if (!field.required || field.hidden) continue;
    else if (!formData.validations[field.name]) {
      unAnswered.push({
        question: field?.label ?? field?.title ?? '',
      });
    }
  }
  return unAnswered;
};
export const allRequiredFilled = (formData: TFormData, fields?: TFormField[]): boolean =>
  !fields
    ? false
    : fields?.every((f) => {
        if (f.hidden) return true;
        return f.required ? formData.values[f.name] !== undefined && formData.values[f.name] !== '' && !f.hidden : true;
      });
export const getPreviouseGroupField = (config: TJourneyConfig, name: string): TFormField | null => {
  let previousField: TFormField | null = null;

  for (const section of config.sections) {
    for (const field of section.fields) {
      if (field.name === name) {
        previousField = field;
      }
    }
  }

  return previousField;
};
export function hexToRGBA(hex: string, alpha: number) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const GTM_ID = process.env.REACT_APP_GTM_ID ?? ''

export const tagManagerArgs = {
  gtmId: GTM_ID
};